<template>
  <div class="look-ppt">
    <h2>扫码查看PPT与互动提问</h2>
    <div class="ppt-content">
      <div class="logo-wrapper">
        <img src="https://f.msup.com.cn/live-wechat-logo.png" alt="" class="wechat-logo" />
        微信扫码
      </div>
      <img
        src="https://f.msup.com.cn/case_miniprogram_code.jpg"
        alt=""
        class="code"
      />
      <p>扫一扫进入小程序查看课程PPT及现场提问互动</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .look-ppt {
    width: 305px;
    border: 1px solid #e5e5e5;
    padding: 27px 19px 31px 22px;
    border-radius: 8px;
    h2 {
      margin-bottom: 20px;
      border-left: 4px solid #0A70C5;
      padding-left: 10px;
      color: #161616;
      font-size: 19px;
      font-weight: 400;
      text-align: left;
    }
    .ppt-content {
      padding-top: 28px;
      padding-bottom: 31px;
      text-align: center;
      background: #ffffff;
      border-radius: 6px;
      .logo-wrapper {
        color: #adadad;
        font-size: 14px;
        .wechat-logo {
          margin-right: 6px;
        }
      }
      .code {
        margin-top: 15px;
        margin-bottom: 18px;
        width: 128px;
        height: 128px;
      }
      p {
        padding: 0 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #6a6a6a;
      }
    }
  }
}
@media (max-width: 767px) {
  .look-ppt {
    width: 100%;
    padding-top: 14px;
    padding-bottom: 20px;
    border-radius: 8px;
    h2 {
      display: none;
    }
    .ppt-content {
      margin-left: auto;
      margin-right: auto;
      width: 67vw;
      padding-top: 30px;
      padding-bottom: 27px;
      text-align: center;
      background: #ffffff;
      border-radius: 6px;
      .logo-wrapper {
        color: #ADADAD;
        font-size: 13px;
        .wechat-logo {
          margin-right: 6px;
          max-width: 6vw;
        }
      }
      .code {
        margin-top: 14px;
        margin-bottom: 17px;
        width: 32.5vw;
        height: 32.5vw;
      }
      p {

        padding: 0 6vw;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #6a6a6a;
      }
    }
  }
}
</style>
